import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, ElementRef, Directive, Inject, Attribute, Output, Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
const MUTATION_OBSERVER_INIT = new InjectionToken('MutationObserver config');
function booleanAttribute(element, attribute) {
  return element.getAttribute(attribute) !== null || undefined;
}
function mutationObserverInitFactory({
  nativeElement
}) {
  const attributeFilter = nativeElement.getAttribute('attributeFilter');
  return {
    attributeFilter: attributeFilter === null || attributeFilter === void 0 ? void 0 : attributeFilter.split(',').map(attr => attr.trim()),
    attributeOldValue: booleanAttribute(nativeElement, 'attributeOldValue'),
    attributes: booleanAttribute(nativeElement, 'attributes'),
    characterData: booleanAttribute(nativeElement, 'characterData'),
    characterDataOldValue: booleanAttribute(nativeElement, 'characterDataOldValue'),
    childList: booleanAttribute(nativeElement, 'childList'),
    subtree: booleanAttribute(nativeElement, 'subtree')
  };
}
const SafeObserver = typeof MutationObserver !== `undefined` ? MutationObserver : class {
  observe() {}
  disconnect() {}
  takeRecords() {
    return [];
  }
};
class MutationObserverDirective extends SafeObserver {
  constructor({
    nativeElement
  }, config, _1, _2, _3, _4, _5, _6, _7) {
    super(records => {
      this.waMutationObserver.emit(records);
    });
    this.waMutationObserver = new EventEmitter();
    this.observe(nativeElement, config);
  }
  ngOnDestroy() {
    this.disconnect();
  }
}
MutationObserverDirective.ɵfac = function MutationObserverDirective_Factory(t) {
  return new (t || MutationObserverDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(MUTATION_OBSERVER_INIT), i0.ɵɵinjectAttribute('attributeFilter'), i0.ɵɵinjectAttribute('attributeOldValue'), i0.ɵɵinjectAttribute('attributes'), i0.ɵɵinjectAttribute('characterData'), i0.ɵɵinjectAttribute('characterDataOldValue'), i0.ɵɵinjectAttribute('childList'), i0.ɵɵinjectAttribute('subtree'));
};
MutationObserverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MutationObserverDirective,
  selectors: [["", "waMutationObserver", ""]],
  outputs: {
    waMutationObserver: "waMutationObserver"
  },
  exportAs: ["MutationObserver"],
  features: [i0.ɵɵProvidersFeature([{
    provide: MUTATION_OBSERVER_INIT,
    deps: [ElementRef],
    useFactory: mutationObserverInitFactory
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MutationObserverDirective, [{
    type: Directive,
    args: [{
      selector: '[waMutationObserver]',
      exportAs: 'MutationObserver',
      providers: [{
        provide: MUTATION_OBSERVER_INIT,
        deps: [ElementRef],
        useFactory: mutationObserverInitFactory
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MUTATION_OBSERVER_INIT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['attributeFilter']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['attributeOldValue']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['attributes']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['characterData']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['characterDataOldValue']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['childList']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['subtree']
      }]
    }];
  }, {
    waMutationObserver: [{
      type: Output
    }]
  });
})();
class MutationObserverService extends Observable {
  constructor({
    nativeElement
  }, config) {
    super(subscriber => {
      const observer = new MutationObserver(records => {
        subscriber.next(records);
      });
      observer.observe(nativeElement, config);
      return () => {
        observer.disconnect();
      };
    });
  }
}
MutationObserverService.ɵfac = function MutationObserverService_Factory(t) {
  return new (t || MutationObserverService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(MUTATION_OBSERVER_INIT));
};
MutationObserverService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MutationObserverService,
  factory: MutationObserverService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MutationObserverService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MUTATION_OBSERVER_INIT]
      }]
    }];
  }, null);
})();
class MutationObserverModule {}
MutationObserverModule.ɵfac = function MutationObserverModule_Factory(t) {
  return new (t || MutationObserverModule)();
};
MutationObserverModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MutationObserverModule
});
MutationObserverModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MutationObserverModule, [{
    type: NgModule,
    args: [{
      declarations: [MutationObserverDirective],
      exports: [MutationObserverDirective]
    }]
  }], null, null);
})();

/**
 * Public API Surface of @ng-web-apis/mutation-observer
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MUTATION_OBSERVER_INIT, MutationObserverDirective, MutationObserverModule, MutationObserverService, SafeObserver };
